/* Background de la model (alert produit):color+taille+emplacement */
.modalBackground {
  background-color: transparent;
  position: absolute;
  left: 30%;
  top:23%
} 
/* css de la contenue de la model */
.modalContainer {
  width: 70%;
  height: 500px;
  border-radius: 5px;
  border-color:black;
  background-color: white;
  box-shadow: rgba(10, 10, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}
/* bouton close */
.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
  color:#F90B0B;
}
/* corps */
.modalContainer .body {
  align-items: center;
  font-size: 1rem;
  text-align: center;
}

 /* Pied model */
 /* les  boutons*/
 .modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

 /* bouton annuler*/
#cancelBtn {
  background-color: crimson;
}
